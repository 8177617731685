.User_Manager{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 20px;
    background-color: rgb(255, 255, 255);
    overflow: auto;
    row-gap: 15px;
}

.User_Manager_Filter{
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.User_Manager_Filter #filterDropdown{
    display: flex;
    padding: 5px 10px;
    outline: none;
    border-radius: 5px;
}

.User_Manager_Table{
    border: 1px solid rgb(217, 217, 217);
    width: 100%;
}

thead, tbody{
    border-radius: 10px;
}

table, th, td {
    border-collapse: collapse;
}

.UsersTable_row.UserTable_title th:first-child {
    display: flex;
    padding-left: 20px;
    height: 40px;
    align-items: center;
    column-gap: 5px;
}

.UsersTable_row.UserTable_title th:first-child ion-icon{
    font-size: 16px;
    --ionicon-stroke-width: 100;
}

.User_Manager_Table .UserTable_title .td_align_center{
    text-align: center;
}

.User_Manager_Table .UserTable_title{
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    color: white;
    border-radius: 10px 10px 0 0;
}

.User_Manager_Table .UserTable_title th{
    text-align: left;
}

.UsersTable_row{
    width: 100%;
    height: 46px;
    border-bottom: 1px solid #efefef;
    font-size: 14px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}

.UsersTable_row:hover{
    background-color: #efefef;
}

.UsersTable_row_emailBox{
    display: flex;
    align-items: center;
    column-gap: 7px;
}

.UsersTable_row_emailBox > img{
    display: block;
    width: 18px;
    height: 18px;
}

.UsersTable_row_nameBox{
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding-left: 20px;
}

.UsersTable_row_nameBox > div{
    position: relative;
}

.UsersTable_row_nameBox > p{
    font-weight: 500;
}

.UsersTable_row_nameBox > div > img{
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    position: relative;
}

.user_active_status{
    display: flex;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 10px;
    border: 2px solid white;;
    position: absolute;
    bottom: -2px;
    right: -2px;
}

.online_status{
    background-color: rgb(0, 192, 0);
}

/* Button set Admin */
.switch {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 16px;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background: #a0a0a0;
    border-radius: 100rem;
    transition: background-color 0.25s ease-in-out;
}
  
.switch:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background-color: #fff;
    top: 2px;
    left: 2px;
    transition: 0.25s ease-in-out;
}
  
.switch-input {
    display: none;
}
  
.switch-input:checked + .switch {
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
}
  
.switch-input:checked + .switch:after {
    transform: translateX(24px);
    background-color: white;
}