.Admin_Dashboard{
    display: grid;
    width: 100%;
    grid-template-areas: "nav  main"
                         "nav  main";
    grid-template-columns: 270px 1fr;
    height: calc(100vh - 50px);
}

.Left_Nav{
    grid-area: nav;
}

.User_Manager{
    grid-area: main;
}