.Course{
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    overflow: hidden;
    width: 350px;
    transition: 0.2s ease-in-out;
}

.Course:hover{
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.2);
}

.Course_img{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 220px;
}

.Course_img > img{
    display: block;
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
}

.Course_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 30px 30px 35px 30px;
    height: 280px;
}

.Course_text_top{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.Course_text_top h3{
    font-size: 18px;
    font-weight: 800;
}

.Course_text_top p{
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
}

.Course_text--tag{
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 2px 10px;
    border-radius: 3px;
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.Course_text_bottom{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.Course_text_bottom > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 7px;
}

.Course_text_bottom > div > span{
    font-size: 14px;
}

.Course_text_bottom > div > ion-icon{
    font-size: 20px;
}

.Course_text_bottom > span{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #E8E8E8;
    width: 45px;
    height: 45px;
    font-weight: 700;
    font-size: 12px;
}