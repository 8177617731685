.Left_Nav{
    display: flex;
    flex-direction: column;
    border-right: 2px solid #eeeeee;
    height: calc(100vh - 50px);
    justify-content: space-between;
}

.Left_Nav_Title{
    padding: 20px;
    border-bottom: 2px solid #eeeeee;
}

.Left_Nav_Title > h4{
    font-weight: 500;
}

.Left_Nav_Title > h2{
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
    font-style: italic;
}

.Left_Nav_link{
    display: flex;
    text-decoration: none;
    color: black;
    align-items: center;
    column-gap: 10px;
    font-weight: 500;
    padding: 15px 20px;
}

.nav_active{
    position: relative;
    background-color: #f0f0f0;
}

/* Foo */
.LeftNav_footer{
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    color: white;
    padding: 15px 0 20px 20px;
    border-radius: 0 20px 0 0;
}

.LeftNav_footer > .Footer_left--line1 {
    font-size: 24px;
}

.LeftNav_footer > div{
    column-gap: 5px;
}

.LeftNav_footer > div > span{
    font-size: 16px;
}

.LeftNav_footer > div > img{
    width: 20px;
    height: 20px;
}

.LeftNav_footer > p {
    font-size: 10px;
    margin-top: -5px;
}