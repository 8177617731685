.Courses{
    background-color: #ECF1FF;
}

.Courses_list{
    display: grid;
    grid-template-columns: repeat(auto-fit, 350px); /* Tạo các cột tự động, tối thiểu 350px và tối đa 1fr (chia đều không gian) */
    justify-content: center; /* Căn giữa theo chiều ngang */
    justify-items: center; /* Căn giữa theo chiều ngang trên hàng */
    gap: 60px; /* Khoảng cách cố định giữa các cột */
    align-items: center;
    padding: 60px 50px;
}

.Container_courses_label{
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: fit-content;
    position: relative;
    margin: 0 auto;
}

.Container_courses_label::after{
    content: ''; 
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -10px; 
    width: 40%;
    height: 3px; 
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    border-radius: 4px 4px 0 0;
}

.Container_courses_label > span{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    color: white;
    font-size: 10px;
    font-weight: 600;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: -25px;
}

.Container_courses{
    display: flex;
    flex-direction: column;
    padding-top: 60px;
}