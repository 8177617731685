.Home{
    background-color: #ECF1FF;
}

.Home_cover{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.Home_cover img{
    display: block;
    width: 45%;
}

.Home_cover_right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 60px;
    margin-bottom: 100px;
    position: relative;
    z-index: 10;
    position: relative;
}

.Home_cover_right::before{
    content: '';
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: #DEE7FF;
    position: absolute;
    z-index: -1;
    right: -130px;
    bottom: -50px;
}

.Cover_right_logo{
    display: flex;
    column-gap: 15px;
    margin-bottom: 20px;
}

.Cover_right_logo img{
    display: block;
    width: 60px;
}

.Cover_right_text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.Cover_right_text span{
    font-family: 'Ms Madi', cursive;
    font-size: 80px;
    margin-bottom: -20px;
}

.Cover_right_text h1{
    text-align: right;
    font-family: Montserrat;
    font-size: 65px;
    font-style: italic;
    font-weight: 900;
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Cover_right_text p{
    font-size: 38px;
    font-weight: 600;
    font-style: italic;
}

.Home_cover_right a{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    text-decoration: none;
    padding: 10px 30px 10px 35px;
    font-size: 18px;
    font-weight: 600;
    column-gap: 10px;
    margin-top: 30px;
}

.Home_cover_right a ion-icon{
    font-size: 25px;
}

.Home_benefit{
    display: flex;
    flex-direction: column;
    margin: 50px auto 0px;
    align-items: center;
}

.Home_benefit_container{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
}

.HBe_container--item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 350px;
    min-height: 550px;
    background-color: white;
    border-radius: 10px;
}

.HBe_container--item .HBe_container--item_img{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.HBe_container--item .HBe_container--item_img > img{
    display: block;
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
}

.HBe_container--item .HBe_container--item_text{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 30px 40px 30px;
    row-gap: 10px;
}

.HBe_container--item_text > span{
    font-family: 'Meow Script', cursive;
    font-size: 35px;
    margin: -10px 0;
}

.HBe_container--item_text > h4{
    text-align: center;
    font-weight: 800;
    font-size: 19px;
}

.HBe_container--item_text > p{
    text-align: justify;
    font-size: 16px;
}

.scalex09{
    transform: scale(0.88);
}