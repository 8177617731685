.Resources_cover{
    display: flex;
    position: relative;
}

.Resources_cover > img{
    display: block;
    width: 100%;
}

.Resources_cover_text{
    display: flex;
    flex-direction: column;
    color: white;
    width: 40%;
    position: absolute;
    top: 200px;
    right: 80px;
}