@import url('https://fonts.googleapis.com/css2?family=Meow+Script&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,600;1,900&family=Ms+Madi&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb{
  background-color: rgb(202, 202, 202);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
  background-color: rgb(168, 168, 168);
}

::-webkit-scrollbar-track{
  background-color: transparent;
}