.Header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
    /* border-bottom: 1px solid #CACACA; */
    padding: 0 60px;
    background-color: white;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 999;
}

.Header_left{
    display: flex;
    column-gap: 10px;
    align-items: center;
    text-decoration: none;
    color: black;
}

.Header_left img{
    display: block;
    width: 35px;
    height: 35px;
}

.Header_left span{
    font-weight: 700;
}

.Header_right ul{
    display: flex;
    column-gap: 30px;
    list-style: none;
}

.Header_right ul a{
    text-decoration: none;
    color: black;
    font-weight: 600;
    font-size: 16px;
}

#login_button{
    cursor: pointer;
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: 16px;
    border: none;
}

.active_tab{
    position: relative; 
    font-weight: 700;
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.active_tab::after {
    content: ''; 
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px; 
    width: 120%;
    height: 3px; 
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    transform: translateX(-8.5%); 
    border-radius: 4px 4px 0 0;
}

.Header_right_line{
    display: block;
    width: 2px;
    height: 20px;
    background-color: rgb(231, 231, 231);
}

.current_user_box{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
    position: relative;
    cursor: pointer;
}

.current_user_box > img{
    display: block;
    width: 20px;
    border-radius: 50%;
}

.current_user_box span{
    font-weight: 500;
}

.current_user_option{
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 15px 20px 5px 20px;
    border-radius: 6px;
    background-color: white;
    position: absolute;
    right: 60px;
    top: 55px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}

.current_user_option > .user_option_info{
    display: flex;
    align-items: center;
    column-gap: 8px;
    border-bottom: 1.5px solid #E8E8E8;
    padding-bottom: 10px;
}

.current_user_option > .user_option_info > div > span{
    font-weight: 600;
    text-wrap: nowrap;
}

.current_user_option > .user_option_info > div > p{
    font-size: 12px;
}

.current_user_option > .user_option_info > img{
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.current_user_option > .user_option_info > ion-icon{
    font-size: 28px;
}

.user_option_select{
    display: flex;
    align-items: center;
    padding: 10px 0 10px 5px;
    column-gap: 10px;
}

.user_option_select:hover{
    cursor: pointer;
}

.user_option_select span{
    font-size: 14px;
    font-weight: 500;
}

.user_option_select ion-icon{
    font-size: 20px;
}

.overplayForPopup{
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
}

.overplayForLogin{
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

/* Login Box Style */
.Login_popup{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.Popup_Login_title{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Popup_Login_title > span{
    font-weight: 500;
    padding-left: 8px;
    font-size: 18px;
}

.Popup_Login_title > h2{
    font-weight: 900;
    font-style: italic;
    background: var(--Gradient-1, linear-gradient(264deg, #C804CC 18.55%, #6A0CBB 76.81%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 26px;
}

.Login_popup_box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 40px;
    row-gap: 20px;
    width: 50%;
    max-width: 500px;
    background-color: white;
    border-radius: 10px;
    position: relative;
}

/* Close button */
.Login_popup_box > ion-icon{
    cursor: pointer;
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 40px;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: 0.2s;
}

.Login_popup_box > ion-icon:hover{
    background-color: #efefef;
}

.Popup_Login_button{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
}

.Popup_Login_button > button{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    height: 44px;
    font-weight: 500;
    font-size: 15px;
    column-gap: 10px;
    transition: 0.2s;
}

.Popup_Login_button > button:hover{
    background-color: #e3e3e3;
}

.Popup_Login_button > button > img{ 
    display: block;
    width: 22px;
}