.Footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 220px;
    padding: 0 60px;
    background: linear-gradient(267deg, #C804CC -13.32%, #6A0CBB 67.55%);
    color: white;
}

.Footer_left{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.Footer_left div{
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.Footer_left div img{
    display: block;
    width: 28px;
    height: 28px;
}

.Footer_left div span{
    font-weight: 800;
    font-size: 22px;
}

.Footer_left--line1{
    font-family: 'Meow Script', cursive;
    font-size: 30px;
    margin-bottom: -10px;
}

.Footer_left p{
    color: rgba(255, 255, 255, 0.51);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Footer_right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 5px;
}

.Footer_right--title{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 800;
}

.Footer_right--follow{
    display: flex;
    align-items: center;
    column-gap: 8px;
    text-decoration: none;
    color: white;
}

.Footer_right--follow img{
    display: block;
    width: 20px;
}